//React
import { useCallback, useEffect, useRef } from 'react';
//Componentes externos
import Pusher, { Channel } from 'pusher-js';
import { toast } from "react-toastify";
//Hooks externos
import { useQueryClient } from '@tanstack/react-query';
//Interfaces
interface DataWebSocketProps {
  reload?: string;
}
interface UseWebSocketProps {
  channel: string;
  eventName: string;
  condition: boolean;
}
/**
 * useWebSocket
 * @description: Hook para el manejo del webSocket.
 * @date 28/03/2024
 * @param {UseWebSocketProps} { channel, eventName, condition }
 * @returns JSX del Screen.
 */
const useWebSocket = ({ channel, eventName, condition }: UseWebSocketProps) => {
  /* ----------------------------- Hooks ------------------------------------*/
  const queryClient = useQueryClient();
  const pusherRef = useRef<Pusher | null>(null);
  const subscriptionsRef = useRef<{ [key: string]: Channel }>({});
  /* ------------------------------ Funciones -------------------------------*/
  //Manejo de suscripción
  const subscribe = useCallback(
    (onEvent: (data: DataWebSocketProps) => void) => {
      if (!pusherRef.current) {
        pusherRef.current = new Pusher('31b9f2e9fc47d0b0e439', {
          cluster: 'us2',
        });
      }

      if (!subscriptionsRef.current[eventName]) {
        const subscription = pusherRef.current.subscribe(channel);
        subscription.bind(eventName, onEvent);
        subscriptionsRef.current[eventName] = subscription;
      }

      return subscriptionsRef.current[eventName];
    },
    [channel, eventName]
  );
  //Manejo para eliminar suscripción
  const unsubscribe = useCallback(() => {
    if (subscriptionsRef.current[eventName]) {
      subscriptionsRef.current[eventName].unbind(eventName);
      pusherRef.current?.unsubscribe(channel);
      delete subscriptionsRef.current[eventName];
    }
  }, [channel, eventName]);
  /* --------------------------- useEffect's --------------------------------*/
  //Maneja la suscripción y eliminar la suscripción
  useEffect(() => {
    //Variable que guarda
    const audio = new Audio(require("../sound/notification.wav"));
    //Se invalida query y se manda mensaje
    const onEvent = ({ reload }: DataWebSocketProps) => {
      if (channel === "reaccion") {
        document.title = "Reacción agregada";
        audio.play();
        toast.success("Reacción agregada al sistema", {
          toastId: reload,
        });
      }
      if (channel === "evento") {
        document.title = "Evento agregado";
        audio.play();
        toast.success("Evento agregado a la reacción", {
          toastId: reload,
        });
      }
      //Se invalida query
      queryClient.invalidateQueries({ queryKey: reload?.split("-") });
    };
    //Dependiendo de la condición se suscribe o no al WebSocket
    if (condition) {
      subscribe(onEvent);
      //Función de limpieza, se elimina la suscripción
      return () => {
        unsubscribe();
      };
    }
  }, [subscribe, unsubscribe, condition, queryClient, channel]);

  //Maneja la eliminación de todas las suscripciones al desmontar el componente
  useEffect(() => {
    return () => {
      Object.values(subscriptionsRef.current).forEach((subscription) => {
        subscription.unbind();
        pusherRef.current?.unsubscribe(subscription.name);
      });
      subscriptionsRef.current = {};
    };
  }, []);
};

export default useWebSocket;